import React, { useEffect, useState, useCallback } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
import Gallery from "react-photo-gallery"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"
import Carousel, { Modal, ModalGateway } from "react-images"

import { makeStyles, Container } from "@material-ui/core"

import SEO from "../components/seo"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  test: {
    "& .MuiButtonBase-root": {
      backgroundColor: "#003e96",
      padding: "6px 12px",
      borderRadius: 10,
    },
  },
  mainContainer: {
    padding: "6%",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const GalleryPage = ({ pageContext }) => {
  const classes = useStyles()
  const [t, i18n] = useTranslation()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [galleryPhotos, setGalleryPhotos] = useState({})
  const [lightBoxPhotos, setLightBoxPhotos] = useState({})

  const { pLang } = pageContext

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(
        pLang,
        "gallery-page",
        `/${i18n.language}/gallery-page`
      )
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(
          pLang,
          "gallery-page",
          `/${i18n.language}/gallery-page`
        )
      } else {
        documentGlobal.location.href = `/${i18n.language}/gallery-page`
      }
    })
  }, [i18n, pLang])

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const breadcrumbs = [
    {
      text: t("breadcrumbs.gallery"),
    },
  ]

  useEffect(() => {
    axios.get(`${process.env.GATSBY_API_URL}/galleries`).then(res => {
      if(Object.keys(res.data).length > 0) {
      const lastStateForGalleryImages = res.data.map(function (item, key) {
        return {
          src: `${process.env.GATSBY_API_URL}${item.image.formats.small.url}`,
          width: 4/* (key % 2 === 0 ? 3 : 4) */,
          height: 4/* (key % 2 === 0 ? 4 : 3) */
        }
      })
      const lastStateForLightBoxImages = res.data.map(function (item, key) {
        return {
          src: `${process.env.GATSBY_API_URL}${item.image.url}`
        }
      })
      setLightBoxPhotos(lastStateForLightBoxImages)
      setGalleryPhotos(lastStateForGalleryImages)
      } else {console.log("No Photos ;)")}
    })
  }, [])

  return (
    <>
      <SEO title={t("breadcrumbs.gallery")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("breadcrumbs.gallery")} />
      <Container className={classes.mainContainer}>
        {Object.keys(galleryPhotos).length > 0 && <Gallery photos={galleryPhotos} onClick={openLightbox} />}
        <ModalGateway>
          {Object.keys(lightBoxPhotos).length > 0 ? viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={lightBoxPhotos.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null : ""}
        </ModalGateway>
      </Container>
    </>
  )
}
export default GalleryPage
